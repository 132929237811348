@tailwind components;
@tailwind utilities;
@import '~@microfrontends/bootstrap/scss/functions';
@import '~@microfrontends/bootstrap/scss/variables';
@import '~@microfrontends/bootstrap/scss/mixins';
@import './awesome-bootstrap-checkbox';
@import './animation';

/*
* VNW-17406: Search result performance
* Jobitem.css is a long task on google analytic when import
* Remove used jobitem.css and add css in there - global.css
*
* @author Tri, Tran Minh
* @since 25 Aug, 2022
*/

@import '~react-toastify/dist/ReactToastify.css';
$colorGrey: #999;
html {
    font-size: 14px;
}

body {
    color: #333;
}

.ASideBlock {
    .top-slots {
        >div {
            margin-bottom: 16px;
        }
    }
    @media screen and (max-width: 991px) {
        .top-slots {
            display: none;
        }
    }
}

.search-box-wrapper {
    flex-basis: 73% !important;
}

.location-wrapper {
    flex-basis: 27% !important;
}

:focus {
    // outline-color: #005AFF;
    outline: none;
}

.clickable {
    cursor: pointer;
}

.contentContainer {
    padding: 0 12.5%;
    margin-top: 15px;
    .alignedCol {
        flex: 0 0 70%;
        max-width: 70%;
        padding: 0 16px 0 16px;
    }
    .alignedCol-full {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .main-right-col {
        padding-right: 15px;
        flex: 0 0 30%;
        max-width: 30%;
    }
    @media all and (min-width: 992px) and (max-width: 1299px) {
        padding: 0;
    }
    @media (max-width: 991px) {
        padding: 0 16px;
        .alignedCol {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
        }
        .main-right-col {
            flex: 0 0 100%;
            max-width: 100%;
            padding-right: 0;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 0;
        .alignedCol {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
        }
    }
}

.mtBlock {
    margin-top: 16px;
}

.headingSection {
    background: white;
    border-bottom: 1px solid #e8e8e8;
}

.main-content {
    background: #f8f9fa;
    margin-top: 0;
    padding-bottom: 16px;
    .stickWithHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: white;
    }
}

.process-placeholder {
    height: 3px;
    display: none;
    position: fixed;
    top: 0;
    z-index: 999;
    &.has-process {
        display: block;
    }
}

.progress-line,
.progress-line:before {
    height: 3px;
    width: 100%;
    margin: 0;
}

.progress-line {
    background-color: #fde5c8;
    display: flex;
    position: fixed;
}

.progress-line:before {
    background-color: #ff7d55;
    border-radius: 3px;
    content: '';
    -webkit-animation: running-progress 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    animation: running-progress 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

.noResultWrapper {
    padding: 30px;
    background: white;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    a {
        color: #005aff !important;
        text-decoration: none;
        &:hover {
            color: #ff7d55 !important;
        }
    }
    @media screen and (min-width: 992px) {
        .hide-on-mobile {
            display: none;
        }
    }
}

@-webkit-keyframes running-progress {
    0% {
        margin-left: 0px;
        margin-right: 100%;
    }
    50% {
        margin-left: 25%;
        margin-right: 0%;
    }
    100% {
        margin-left: 100%;
        margin-right: 0;
    }
}

@keyframes running-progress {
    0% {
        margin-left: 0px;
        margin-right: 100%;
    }
    50% {
        margin-left: 25%;
        margin-right: 0%;
    }
    100% {
        margin-left: 100%;
        margin-right: 0;
    }
}

@media screen and (max-width: 991px) {
    .main-content {
        .stickWithHeader {
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            z-index: 5;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    .main-content {
        margin-top: 0;
    }
}

@media screen and (max-width: 767px) {
    .main-content {
        .stickWithHeader {
            margin-bottom: 0;
        }
    }
}

.notification-icon {
    svg {
        width: 18px !important;
        height: 18px !important;
    }
}

.noResultBlock {
    .img-noresult {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        border: 1px solid #eeeeee;
        margin: 20px 0px;
        margin-top: 0;
        text-align: center;
        background-color: #ffffff;
        img {
            width: 250px;
            margin-bottom: 15px;
        }
        .wrap-conditions {
            .query-condition {
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .main-text {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
        padding: 0 50px;
    }
    .second-text {
        font-size: 14px;
    }
    .wrapper-no-job {
        border: 1px solid #eeeeee;
        padding: 30px 25px 15px 25px;
        background-color: #ffffff;
        .m-l {
            margin-bottom: 20px;
            position: relative;
            font-size: 1.5rem;
            &:after {
                position: absolute;
                content: '';
                left: 3px;
                bottom: -20px;
                width: 98%;
                height: 1px;
                background: #eee;
            }
        }
        .job-item {
            position: relative;
            &:after {
                position: absolute;
                content: '';
                left: 15px;
                bottom: 0;
                width: 96%;
                height: 1px;
                background: #eee;
            }
            &:last-child {
                padding-bottom: 0;
                &:after {
                    content: none;
                }
            }
            padding: 20px 0;
            .job-item__img-noresult {
                width: 100px;
                margin: 0 auto;
                .pio-item-link {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .text-clip {
                max-width: 100%;
                vertical-align: middle;
            }
            .text-info {
                display: inline-block;
            }
            .pio-item-link {
                transition: all 0.2s;
                &:hover {
                    text-decoration: none;
                }
                .title {
                    color: #565656;
                    margin-bottom: 10px;
                    font-size: 18px;
                    cursor: pointer;
                    font-weight: 600;
                    &:hover {
                        color: #ff7d55;
                    }
                }
            }
            .company {
                margin-bottom: 5px;
                font-size: 14px;
                line-height: 17px;
                font-weight: 600;
                a {
                    color: #333;
                    transition: color 0.3s;
                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: #ff9100;
                    }
                }
            }
            .extra-info {
                font-size: 14px;
                .job-search__salary {
                    color: $colorGrey;
                }
                .salary {
                    color: #ff7d55;
                    a {
                        transition: color 0.3s;
                    }
                    a:hover {
                        color: #ff7d55;
                        text-decoration: none;
                    }
                }
                .separator {
                    padding-left: 10px;
                    padding-right: 10px;
                    color: #ccc;
                }
                .job-search__location {
                    color: $colorGrey;
                }
                .job-search__date-posted {
                    color: $colorGrey;
                }
                span {
                    strong {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .noResultBlock {
        .img-noresult {
            .main-text {
                font-size: 20px;
            }
            .second-text {
                font-size: 14px;
            }
        }
        .wrapper-no-job {
            padding: 40px;
            .job-item {
                .job-item__img-noresult {
                    width: auto;
                }
                .job-item__col-noresult {
                    margin: auto;
                    align-items: center !important;
                    width: 25%;
                    padding: 0;
                }
                .img-responsive {
                    height: 100% !important;
                }
                .col-xs-9 {
                    width: 75%;
                }
                .text-clip {
                    display: block !important;
                }
                .text-info {
                    display: block;
                }
                .separator {
                    display: none;
                }
                padding-left: 15px;
                padding-right: 0px;
            }
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

@media (max-width: 767px) {
    #filterScroll::-webkit-scrollbar {
        display: none;
    }
    #quickfilterScroll::-webkit-scrollbar {
        display: none;
    }
    .item-filter {
        margin-left: -8px;
    }
    .noResultBlock {
        background: rgb(248, 248, 248);
        border: 1px solid #eeeeee;
        margin-top: 16px;
        .img-noresult {
            margin: 0;
            padding: 30px;
            border: none;
            .main-text {
                padding: 0;
                font-weight: 600;
            }
        }
        .wrapper-no-job {
            margin-top: 16px;
            padding: 16px;
            .m-l {
                padding: 0;
                &::after {
                    left: 14px;
                    width: 96%;
                }
            }
            .job-item {
                margin: 0;
                padding: 16px 0;
                .job-item__img-noresult {
                    width: auto;
                }
                .job-item__col-noresult {
                    margin: auto;
                    align-items: center !important;
                    width: 25%;
                    padding: 0;
                }
                .img-responsive {
                    height: 100% !important;
                }
                .col-xs-9 {
                    width: 75%;
                }
                .text-clip {
                    display: block !important;
                }
                .text-info {
                    display: block;
                }
                .separator {
                    display: none;
                }
            }
        }
    }
}

.anonymous-job-alert-dialog {
    min-width: 700px;
    [class*='Dialog-module_footer__'] {
        display: none !important;
    }
    .row {
        margin-left: 0;
        margin-right: 0;
        &:nth-child(2) {
            margin-bottom: 8px;
        }
    }
    .col-input {
        display: inline-block;
        width: 66.6%;
    }
    .col-action {
        display: inline-block;
        width: 33.3%;
        padding-left: 10px;
        >button {
            width: 100%;
        }
    }
    .error-message {
        color: #f85a5a;
    }
    @media screen and (max-width: 768px) {
        min-width: auto;
        .col-input {
            display: block;
            width: 100%;
        }
        .col-action {
            display: block;
            width: 100%;
        }
        [class*='Dialog-module_footer__'] {
            display: flex !important;
        }
    }
}

.backdrop-blur {
    z-index: 1010;
}

@media (min-width: 768px) {
    .col-md-3 {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .col-md-9 {
        flex: 0 0 80%;
        max-width: 80%;
    }
}

.switch-to-job-alert-creator-link,
.switch-to-job-alert-manager-link {
    color: #005aff;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
        color: #ff7d55;
    }
}