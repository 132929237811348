$form-check-input-gutter: 5px;
$theme-colors: (
    "primary": $vnw-orange,
    "info": $vnw-blue
);

@function theme-color($key: "primary") {
    @return map-get($theme-colors, $key);
}

$font-family-icon: 'Open Sans';
$fa-var-check: "";
@import '~awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.scss';

.abc-checkbox {

    // Box icon
    input[type="checkbox"] + label:before {
        left: -10px;
        top: 0;
        outline-color: #005aff !important;
    }

    input:checked + label:before {
        border-color: #005aff;
    }

    input:focus + label:before {
        outline-color: #005aff;
    }

    // Check icon
    label:after {
        left: -9px;
        top: 0;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAclBMVEUAAAAAufIAt+wAufIAufIAufIAufIAufQAtv8AufIAufIAuvIAufMAuPMAufYAufMAufIAufIAufIAufIAufIAuvIAufIAufIAufIAufIAufMAuvIAuvAAufQAu/IAt/EAuPIAtOwAgP8AufEAufIAufIESyjyAAAAJXRSTlMA+wnm0WtdFwXwvraQQBv29O3dx6qimH9zY1NLMysnIxINAruH0suRCgAAAKpJREFUOMvVkVcOwjAQROMUSO+9ksC7/xVpAhGF7DfM167fSDO2tT/XIZB5jTpKfIowdMlwhlbiFuQSDx0SsYEHB/GG4En8mOCEkiGHRuItuBI/GcTT+qix7I+tAGvNR/Dm99ZBtqwNugnmq/VsEI2bVBeM/jmXUG972QXE3X0KwLS/Va9AWbe0FDXsvK4Cf/Gh0nYUOOA+AvY0pIDqpT/K4KJJ0v1S135QV1SlDLgQmd9nAAAAAElFTkSuQmCC");
        display:block;
        width:16px;
        height: 16px;
        background-size: cover;
    }
}
